<template>
  <div v-if="show">
    <v-container class="px-0" fluid>
      <div class="navbar d-flex flex-wrap pt-5">
        <img style="width: 216px" :src="$bucket + '/image/logo-duo-reverse.svg'" @click="$store.commit('debug')" />
        <v-spacer />
        <img style="width: 182px" :src="$bucket + '/image/logo-airnz.svg'" />
      </div>
    </v-container>
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img max-width="1271px" max-height="376px" style="background-size: cover; background-position: center center;"
            :src="entity.heroImage" />
        </v-col>
        <v-col v-if="!entity.map.hideTitle || entity.map.hideTitle == false" cols="12"
          class="d-inline-flex py-0 px-4 justify-space-between align-end">

            <h3 v-if="entity.istnc">Terms and Conditions</h3>
            <h1 v-else-if="!entity.map.headingStyle || entity.map.headingStyle == 'h1'">{{ entity.title }}</h1>
            <h2 v-else-if="entity.map.headingStyle == 'h2'">{{ entity.title }}</h2>
            <h3 v-else>{{ entity.title }}</h3>

        </v-col>
      </v-row>
      <div class="mt-4" v-for="(item, idx) in entity.map.contentList" :key="idx">
        <v-row v-if="item.type == 'Text'" style="padding: 0px 110px 10px 20px">
          <v-col cols="12" class="pa-0">
            <div v-html="contentCss + item.content" />
          </v-col>
        </v-row>
        <v-row v-else-if="item.type == 'Video'" class="mt-0 d-flex justify-center text-center">
          <v-col cols="12">
            <iframe width="1250" height="706" :src="item.content" :title="entity.title" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen />
          </v-col>
        </v-row>
        <v-row v-else-if="item.type == 'Images_Block'" class="pa-0 d-flex" style="padding: 0px 60px 0px 0px;">
          <v-col class="py-4" :cols="!item.content.image2 && !item.content.image3 ? 12 : !item.content.image3 ? 6 : 4">
            <v-img height="360" style="background-size: cover;" :src="item.content.image1" />
          </v-col>
          <v-col v-if="item.content.image2" class="py-4" :cols="!item.content.image3 ? 6 : 4">
            <v-img height="360" style="background-size: cover;" :src="item.content.image2" />
          </v-col>
          <v-col v-if="item.content.image3" class="py-4" cols="4">
            <v-img height="360" style="background-size: cover;" :src="item.content.image3" />
          </v-col>
        </v-row>
        <v-row v-else-if="item.type == 'CTA_Button'" style="padding: 0px 0px 10px 20px">
          <v-col
            :class="'d-flex justify-' + (item.content.position == 'right' ? 'end' : item.content.position == 'left' ? 'start' : 'center')"
            cols="12">
            <v-btn @click="onClickCTA(item.content.link)" min-width="320px" height="50px" color="duoSecondary"
              :elevation="0" dark>{{ item.content.label }}</v-btn>
          </v-col>
        </v-row>
        
        <v-row v-else-if="item.type == 'Terms_and_Conditions'" style="padding: 0px 0px 10px 20px">
          <v-col
            :class="'d-flex justify-' + (item.content.position == 'right' ? 'end' : item.content.position == 'left' ? 'start' : 'center')"
            cols="12">
            <a @click="showDialogTerms(item.content.pages)">{{ item.content.label }}</a>
          </v-col>
        </v-row>
        <div v-else-if="item.type == 'Dynamic_Form' && item.content">
          <duo-dynamic-form :show="show" :is-preview="isPreview" @onFormSubmitted="onFormSubmitted" :question-list="questionMap[item.content]" :is-show-submitted="isShowSubmitted(item.content)" :key="'dynamic_form_' + item.content" />
        </div>
        <div v-else-if="item.type == 'Wayin' && item.content" style="overflow: hidden; height: 700px;">
          <iframe 
            :src= "'https://xd.wayin.com/display/container/dc/' + item.content" 
            style="transform: translateY(-100px)"
            frameborder="0" 
            scrolling="no" 
            width="100%" 
            :id="'ngxFrame' + item.content"
            allowTransparency="true">
          </iframe>
        </div>
      </div>

      <v-dialog v-model="isShowTerms" max-width="800">
        <v-card>
          <v-card-title>Terms and Conditions</v-card-title>
          <v-card-text v-html="tncContent"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="isShowTerms = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="confirmationDialog" max-width="800" v-if="entity.slug === 'YourTicketToGlobalGathering'">
        <v-card style="border-radius: 0.5rem;">
          <div style="background-color: #1C1044; color: white; padding: 1rem;">
            <div style="display:flex; justify-content: end">
              <v-icon large color="white" @click="confirmationDialog = false">mdi-close</v-icon>
            </div>
            <div style="display: flex; flex-direction: column; align-items: center; text-align: center; padding: 0.5rem 1rem;">
              <h2 style="font-family: 'AirNZ Black Italic'; font-size: 36px; font-weight: normal; line-height: 3rem; padding: 0 0 2rem 0;">
                Thanks, you are now registered.
              </h2>
            </div>
          </div>
        </v-card>
      </v-dialog>

    </v-container>
    <v-container style="margin-top:50px;">
      <duoFooter />
    </v-container>
  </div>
</template>
  
<style scoped>
h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
</style>
  
<script>
/* eslint-disable no-console */
import subHeader from "../subHeader.vue";
import duoFooter from "../duoFooter.vue";
import duoDynamicForm from "./duoDynamicForm.vue";
import axios from "axios";

export default {
  components: {
    subHeader,
    duoFooter,
    duoDynamicForm,
  },
  data() {
    return {
      confirmationDialog: true,
      isShowTerms: false,
      tncContent:"",
      contentCss: '<style scoped>ul {margin-left: 48px;}</style>'
    }
  },
  props: {
    isPreview: {
      default: false,
    },
    show: {
      default: true,
    },
    entity: {
      default: {
        map: {
          contentList: []
        }
      }
    },
    questionMap: {
      default: function() {
        return {};
      }
    },
    submittedMap: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
  methods: {
    isShowSubmitted(content) {
      return this.submittedMap[content] | false;
    },
    showDialogTerms(id) {
      this.isShowTerms = true;
      axios.get("/system/creator/page?id=" + id).then(
        response => {
          this.tncContent = response.data.map.contentList[0].content;
        }
      );
    },
    onClickCTA(link) {
      if(link.startsWith('http://') || link.startsWith('https://')) {
        window.location.href = link;
      }
    },
    onFormSubmitted() {
      this.$emit("onReset");
    }
  }
};
</script>